

















































































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ReturnFactory extends Vue {
	pagerHeader: any = {
		title: '返厂记录',
		desc:
			'若要查看超时订单，请分别选择订单状态为【已完成】和归还状态为【未归还】组合查询',
		breadcrumb: ['返厂记录'],
	}
	form: any = {
		keywords: '',
		status: null,
		page: 1,
		pageSize: 10,
	}
	options: any = [
		{
			value: '0',
			label: '维修中',
		},
		{
			value: '1',
			label: '维修完成',
		},
		{
			value: '2',
			label: '已寄回',
		},
	]
	tableData: any ={
        data: [{
			date: '2016-05-02',
			name: '王小虎',
			address: '上海市普陀区金沙江路 1518 弄',
		},
		{
			date: '2016-05-04',
			name: '王小虎',
			address: '上海市普陀区金沙江路 1517 弄',
		},
		{
			date: '2016-05-01',
			name: '王小虎',
			address: '上海市普陀区金沙江路 1519 弄',
		},
		{
			date: '2016-05-03',
			name: '王小虎',
			address: '上海市普陀区金沙江路 1516 弄',
        }],
        totalCount:10
    }
	search() {}
	handleEdit(index: any, row: any) {
		console.log(index, row)
	}
	handleDelete(index: any, row: any) {
		console.log(index, row)
	}
	// 分页开始
	handleSizeChange(val: number) {
		this.form.pageSize = val
		// this.readerPage();
	}
	handleCurrentChange(val: number) {
		this.form.page = val
		// this.readerPage();
	}
}
